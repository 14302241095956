//
// Custom Main
//
// Add your own styles or override existing ones
//
// This file is included last, so you have access
// to all Bootstrap and Dashmix functions/mixins/styles etc
// --------------------------------------------------

// If you have many custom styles, you can create and import additional files
// For example you can create _component1.scss and include it as follows:
// @import 'component1';

@import 'toasts';
@import 'course';
@import 'item';
@import 'file-inputs';

.breadcrumb-item {
	text-transform: capitalize;
}

.once.disabled {
	pointer-events: none;
	opacity: 0.5;
}

figure:not([class]) {display: none;}

.bg-table-header {
    background-color: #F1F5F9;
}

.nav-main-submenu {
	background-color: transparent !important;
}

.nav-main-item.open > .nav-main-link-submenu {
	background-color: transparent !important;
}

.nav-main-link.active {
	background-color: transparent !important;
}

.font-s8 {
	font-size: 8px !important;
	line-height: 8px !important;
}
.font-s9 {
	font-size: 9px !important;
	line-height: 9px !important;
}
.font-s10 {
	font-size: 10px !important;
	line-height: 10px !important;
}
.font-s12 {
	font-size: 12px !important;
	line-height: 12px !important;
}
.font-s14 {
	font-size: 14px !important;
	line-height: 14px !important;
}
.font-s20 {
	font-size: 20px !important;
	line-height: 20px !important;
}

.dm-sans {
	font-family: 'DM Sans', sans-serif;
}
.inter {
	font-family: 'Inter', sans-serif;
}
.open-sans {
	font-family: 'Open Sans', sans-serif;
}

.list-item {
	display: list-item !important;
	list-style: disc !important;
}

@media(min-width: 576px) {
	input.form-control-md, select.form-control-md {
		padding: 12px 20px;
		border-radius: 6px !important;
		height: 42px;
		min-width: 282px;
	}
}
input.form-control-md, select.form-control-md {
	padding: 12px 20px;
	border-radius: 6px !important;
	height: 42px;
}

select {
	appearance: none;
	background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right 1rem center;
	background-size: 1em;
	padding-left: 20px !important;
}

.btn-lg {
	border-radius: 0.5rem;
	padding: 14px 20px;
}

input::placeholder {
	color: #919EAB !important;
}

.content figure img {
	max-width: 100%;
}