.toast-container {
	position: fixed;
	z-index: 1055;
	margin: 5px;

	& > .toast {
		min-width: $toast-max-width;

		.toast-header {
			.close {
				opacity: 1;
				text-shadow: none; 
			}
		}

		.toast-header strong {
			padding-right: 20px;
		}
	}
}

.top-right {
	top: calc(70px + 1rem);
	right: 1.75rem;
}

.top-left {
	top: 0;
	left: 0;
}

.top-center {
	transform: translateX(-50%);
	top: 0;
	left: 50%;
}

.bottom-right {
	right: 0;
	bottom: 0;
}

.bottom-left {
	left: 0;
	bottom: 0;
}

.bottom-center {
	transform: translateX(-50%);
	bottom: 0;
	left: 50%;
}