//
// Breadcrumb
// --------------------------------------------------

.breadcrumb.breadcrumb-alt {
    .breadcrumb-item + .breadcrumb-item::before {
        position: relative;
        top: 1px;
        width: 1rem;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        color: gray("400");
        content: "\f105";
    }
}
