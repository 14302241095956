//
// Custom Variables
//
// Here you can add your own variables overrides
//
// The file is included before any other variable file,
// so you can override them because they all use !default
// --------------------------------------------------

// For example, changing the primary color
// $primary: #3b5998;

$primary: #B80202;
$secondary: #BFBFBF;
$danger: #FF0000;
$warning: #E5A603;
$info: #0099CC;
$success: #098E26;

$gray-200: #F4F6F8;
$gray-300: #CBD5E1;
$gray-600: #475569;
$gray-800: #1E293B;

// $theme-xpro-primary: #1b1c2d;
$theme-xpro-primary: #B80202;
$theme-xpro-header-dark-bg: #B80202;

$border-radius: .5rem;
$btn-hero-border-radius: 0;
$input-border-radius-lg: 1px;
$card-border-radius: 1rem;
$input-border-color: $gray-200;


$headings-font-family: 'DM Sans', sans-serif;
$headings-font-weight: 500;

$toast-color: #fff;
$toast-background-color: #000;
$toast-header-color: #fff;
$toast-header-background-color: #000;
$toast-border-width: 0;
$toast-border-radius: 0;
$toast-box-shadow: 0 10px 10px rgba(0,0,0,0.5);