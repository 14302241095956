.course-item {
	.outline {
		font-size: 8px;

		h1, h2, h3, h4, h5, h6 { 
			margin: 0 0 8px;

			&::before {
				content: none;
			}

			&[data-timestamp]::before {
				content: attr(data-timestamp);
				font-family: $font-family-monospace;
				font-size: 12px;
				margin-right: 1em;
				padding: 0.25em;
				vertical-align: middle;
				background: #ddd;
			}
		}

		$spacer: 1rem;
		h1 { font-size: 1.75em; margin-left: 0; }
		h2 { font-size: 1.5em;  margin-left: $spacer; }
		h3 { font-size: 1.25em; margin-left: ($spacer * 2); }
		h4 { font-size: 1em;    margin-left: ($spacer * 3); }
		h5 { font-size: 0.75em; margin-left: ($spacer * 4); }
		h6 { font-size: 0.5em;  margin-left: ($spacer * 5); }
	}
}