.course-text p:last-of-type {
	margin-bottom: 0;
}
.course-thumbnail {
	position: relative;

	.plus-other {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;

		padding: 0.25rem 2rem;
		color: #fff;
		background: rgba(0,0,0, 0.75);
	}
}